@import "../../../variables";
.MainContainer {
  position: relative;
  max-width: 1200px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 100px 0;

  @media (max-width: $mobile-view-breakpoint) {
    width: calc(100% - 30px);
    padding: 50px 0;
  }
}
