.CustomButton {
  position: relative;
  min-width: 60px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  padding: 15px 25px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  z-index: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  &::before {
    border-radius: 5px;
  }
  background: transparent;
}

.rounded {
  border-radius: 100px;
  &::before {
    border-radius: 100px;
  }
}
.disabled,
.loading {
  background-color: #4e4b72;
  background-image: none;
  color: rgba(#fff, 0.5);
  pointer-events: none;
}
.noclick {
  pointer-events: none;
}

@media (max-width: 765px) {
  .CustomButton {
    font-size: 14px;
  }
}
