@import "../../_variables.scss";
.button {
  cursor: pointer;
}
@media (max-width: $wide-view-breakpoint) {
 
}

@media (max-width: $mobile-view-breakpoint) {
  
}
