@import "../../../variables";
.launchProfile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
  > img {
    margin-bottom: 1em;
    width: 50%;
  }
  .loginInfo {
    > div {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #ffeb34;
    }
    > p {
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */

      text-align: center;
      text-decoration-line: underline;

      color: #3edbf0;
    }
  }
}
@media (max-width: $wide-view-breakpoint) {
  .launchProfile {
    gap: 20px;
    > img {
      width: 75%;
    }
  }
}
