.pixelButton {
  position: relative;
  max-width: 250px;
  margin: 0 auto;
  cursor: pointer;
  text-overflow: ellipsis;
  > img {
    width: 100%;
  }
  > p {
    color: rgba(62, 219, 240, 1);
    position: absolute;
    left: 15%;
    top: 38%;
    font-size: 12px;
  }
}
