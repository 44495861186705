@import "../../_variables.scss";
.Header {
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
  position: absolute;
  top: 0;
  // left: 15%;
  z-index: 1;
  .connectButton {
    font-size: 16px;
    line-height: 16px;
    padding: 20px 30px;
    color: #cdcdcd;
  }
  .addrWrapper {
    font-size: 12px;
    line-height: 12px;
    color: #cdcdcd;
    text-overflow: ellipsis;
  }
}
@media (max-width: $wide-view-breakpoint) {
  .Header {
    width: 100%;
    left: 0%;
  }
}

@media (max-width: $mobile-view-breakpoint) {
  .Header {
    > img {
      width: 40%;
    }
  }
}
