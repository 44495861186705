@import "../variables";
.Home {
  width: 100%;
  position: relative;
  background-color: #000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner {
    position: relative;
    height: 95vh;
    width: 100%;
    max-width: 1920px;
    max-height: 1080px;
    background-position: center center;
    background-size: cover;
    // .bannerBg {
    //   width: 100%;
    //   max-width: none;
    // }
    img {
      max-width: none;
    }
    .bannerBottom {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 100%;
    }
    // height: 95%;
    // width: $main-container-percent;
    // margin: 0 auto;
    // position: relative;
    // .bannerBottom {
    //   height: 20%;
    //   position: absolute;
    //   bottom: -10%;
    //   left: 0;
    //   width: 100%;

    //   > img {
    //     width: 100%;
    //   }
    // }
  }
  .didWrapper {
    // background: #1e1e1e;
    background-color: #000;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;

    width: 100%;
    margin: 0 auto;

    .launchInfo {
      width: $main-container-percent;
      display: flex;
      justify-content: space-between;
      padding-top: 200px;
    }
    .claim {
      width: $main-container-percent;
      .claimTitle {
        color: rgba(62, 112, 240, 1);
        text-align: center;
      }
      .choiceGroup {
        // text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .choiceButton {
          background-color: black;
          border: 2px solid transparent;
        }
      }
      .chosenBorder {
        border: 2px solid #9b22ec !important;
      }
      > h3 {
        color: #1d6dcb;
        text-align: center;
        font-size: 14px;
        line-height: 200%;
      }
      .claimConnectButton {
        margin: 0 auto;
        padding: 10px 20px;
        color: #3edbf0;
        border-color: #3edbf0;
        font-size: 16px;
        line-height: 24px;
      }
      .claimLoginDetail {
        font-size: 14px;
        line-height: 2em;
        > p {
          text-align: center;
          color: #ffeb34;
        }
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          > p {
            margin-left: 1%;
            text-align: center;
            text-decoration-line: underline;
            color: #3edbf0;
          }
          a {
            text-decoration-line: underline;
            color: #3edbf0;
          }
        }
        .inviteInput {
          background-color: #000;
          border: 2px solid #3e70f0;
          color: #3e70f0;
          border-radius: 8px;
          padding: 30px 20px;
          width: 100%;
          max-width: 500px;
          margin-left: 60px;
        }
        .inviteInput::placeholder {
          color: #3e70f0;
        }
        .inviteInput:focus {
          outline: none !important;
          border: 2px solid #3e70f0;
        }
        .inviteButton{
          color: #3EDBF0;
          position: relative;
          left: -100px;
        }
      }
    }
    .referralInfo {
      width: $main-container-percent;
      text-align: left;
      color: #3e70f0;
      width: $main-container-percent;
      display: flex;
      justify-content: space-between;
      .referralSpaceHolder {
        width: 50%;
      }
      .referralContent {
        padding-bottom: 10%;
        width: 50%;
        color: #3e70f0;
        > h1 {
          font-size: 32px;
          line-height: 160%;
        }

        > p {
          font-size: 14px;
          line-height: 18px;
          color: #1d6dcb;
        }
        .referralDetail {
          width: 100%;
          .referralLink {
            border: 2px solid #3e70f0;
            color: #3e70f0;
            border-radius: 8px;
            padding: 15px 20px;
          }
          .referralLinkCopy {
            width: 100%;
            border-color: #3edbf0;
            color: #3edbf0;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .footer{
    background: #110d70; /* Old browsers */
    background: -moz-linear-gradient(left, #110d70 0%, #4b0d6f 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #110d70 0%,#4b0d6f 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #110d70 0%,#4b0d6f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#110d70', endColorstr='#4b0d6f',GradientType=1 ); /* IE6-9 */
    width: 95%;
    border-radius: 30px;
    padding: 40px 20px;
    margin: auto;
    margin-bottom: 5vh;
    font-size: 2.5em;
    display: flex;
    div:nth-child(2) {
      flex: 1.5;
    }
    div {
      color: #3E70F0;
      display: flex;
      flex-direction: row;
      align-items:center;
      justify-content: center;
      flex: 1;
      img {
        width: 85%;
        max-width: 180px;
        margin: 15px;
      }
      .nftItem{
        display: flex;
        flex-direction: column;
        flex: 1;
        .nftLabel{
          color: #ffeb34;
          flex: 1;
          font-size: 15px;
          line-height: 2em;
        }
      }
    }
  }
}
@media (max-width: $wide-view-breakpoint) {
  .Home {
    // background-size: 100% 100vh;
    // .banner {
    //   width: 100%;
    // }
    .didWrapper {
      width: 100%;
      .claim {
        .claimLoginDetail {
          .inviteInput {
            font-size: 10px;
            margin-left: 0;
          }
          .inviteButton{
            width: 100%;
            left: 0;
            margin-top: 20px;
          }
        }
      }
      .launchInfo {
        flex-direction: column-reverse;
        gap: 50px;
        padding-top: 100px;
      }
      .referralInfo {
        .referralSpaceHolder {
          display: none;
        }
        .referralContent {
          width: 100%;
          text-align: center;
        }
      }
    }
    .footer{
      font-size: 1.4em;
      flex-direction: column;
      div {
        flex-direction: column;
        img{
          width: 80%;
          margin: 20px;
        }
      }
    }
  }
}
@media (max-width: $mobile-view-breakpoint) {
  .Home {
    .banner {
      height: 35vh;
      .bannerBottom {
        width: 125%;
      }
    }
    .didWrapper {
      .claim {
        .claimLoginDetail {
          > div {
            flex-direction: column;
            gap: 10px;
            font-size: 12px;
          }
        }
      }
      .referralInfo {
        .referralContent {
          > h1 {
            font-size: 24px;
            line-height: 140%;
          }
          > p {
            font-size: 12px;
            line-height: 140%;
          }
          .referralDetail {
            .referralLink {
              font-size: 12px;
              line-height: 140%;
            }
          }
        }
      }
    }
  }
}
