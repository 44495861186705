@import "../../_variables.scss";
.claim_area{
  margin-top: 20px;
  flex-direction: column;
  color: #ffeb34;
  button {
    cursor: pointer;
    color: #3EDBF0;
    border-radius: 10px;
    padding: 15px !important;
    border: #3EDBF0 3px solid;
    margin: 20px;
    margin-top: 20px;
    width: 20em;
  }
}
@media (max-width: $wide-view-breakpoint) {
 
}

@media (max-width: $mobile-view-breakpoint) {
  
}
