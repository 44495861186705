@import "../../../variables";
.launchIntro {
  // display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  text-align: left;
  gap: 5px;
  .launchTime {
    background: rgba(20, 6, 37, 1);
    color: #b13cff;
    padding: 10px;
    font-size: 16px;
    line-height: 24px;
  }
  > h2 {
    font-size: 32px;
    line-height: 51.2px;
    color: rgba(62, 112, 240, 1);
    margin-top: 15px;
  }
  > h6 {
    font-size: 14px;
    line-height: 17.82px;
    color: rgba(29, 109, 203, 1);
  }
  > h5 {
    font-size: 16px;
    line-height: 20.36px;
    color: rgba(29, 109, 203, 1);
  }

  .supplyDetail {
    display: flex;
    flex-direction: column;
    .supplyDetailItem{
      flex: 1;
      margin-bottom: 2em;
    }
  }
  .progress {
    position: relative;
    width: 100%;
    .progressDisplay {
      width: 100%;
      position: relative;
      height: 15px;
      margin: 10px auto;
      .onGoingBar,
      .totalBar {
        position: absolute;
        left: 0;
        top: 0;
      }
      .onGoingBar {
        > img {
          width: 300%;
          height: 15px;
          object-fit: fill;
        }

        z-index: 1;
      }
      .totalBar {
        width: 100%;
        > img {
          width: 120%;
          height: 15px;
          object-fit: fill;
        }
      }
    }
    > p {
      color: rgba(62, 112, 240, 1);
    }
    .progressDetail {
      display: flex;
      justify-content: space-between;
      > p {
        color: rgba(62, 219, 240, 1);
        > span {
          color: rgba(62, 112, 240, 1);
        }
      }
    }
  }
}
@media (max-width: $mobile-view-breakpoint) {
  .launchIntro {
    .launchTime {
      font-size: 12px;
      align-self: center;
    }
    > h2 {
      font-size: 24px;
      line-height: 140%;
      text-align: center;
    }
    > h6 {
      font-size: 12px;
      line-height: 140%;
    }
    > h5 {
      font-size: 14px;
      line-height: 140%;
    }
  }

  .progress {
    font-size: 14px;
    .progressDetail {
      font-size: 12px;
    }
  }
}
@media (max-width: 1230px) {
  .supplyDetail {
    flex-direction: column;
    gap: 5px;
    align-items: stretch;
  }
}
